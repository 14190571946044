import createAction from '../../common/services/create-action';
import { POST_ADD_SOLVED_COMMENT } from '../components/messages/message-types';
import { showMessage } from '../../common/messages/framework/store/message-actions';

export const ADD_BEST_ANSWER_REQUEST = 'post/ADD_BEST_ANSWER_REQUEST';
export const ADD_BEST_ANSWER_SUCCESS = 'post/ADD_BEST_ANSWER_SUCCESS';
export const ADD_BEST_ANSWER_FAILURE = 'post/ADD_BEST_ANSWER_FAILURE';

const addBestAnswerRequest = createAction(ADD_BEST_ANSWER_REQUEST);
const addBestAnswerSuccess = createAction(ADD_BEST_ANSWER_SUCCESS);
const addBestAnswerFailure = createAction(ADD_BEST_ANSWER_FAILURE);

export function addBestAnswer(postId, commentId) {
  return (dispatch, getState, { request }) => {
    dispatch(addBestAnswerRequest());

    const promise = request.post(`/posts/${postId}/best-answer`, { commentId });
    return promise
      .then(post => dispatch(addBestAnswerSuccess(post)), () => dispatch(addBestAnswerFailure()))
      .then(() => promise);
  };
}

export function addBestAnswerAndNotify(postId, commentId) {
  return dispatch =>
    dispatch(addBestAnswer(postId, commentId)).then(() =>
      dispatch(showMessage(POST_ADD_SOLVED_COMMENT)),
    );
}
