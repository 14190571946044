import { get, noop, debounce, once } from 'lodash';
import { createStore, applyMiddleware, bindActionCreators } from 'redux';
import { composeWithDevTools } from 'remote-redux-devtools';
import thunk from 'redux-thunk';
import uri from 'urijs';
import * as Raven from 'raven-js';
import { LIKE_POST } from '../../app/constants/interactions';
import createRequest from '../../common/services/create-request';
import fetchRecentPosts from '../actions/fetch-recent-posts';
import {
  incrementPostLikeCount,
  INCREMENT_POST_LIKE_COUNT_SUCCESS,
} from '../../common/actions/increment-post-like-count';
import reducers from '../reducers';
import { fetchAllCategories } from '../../common/actions/fetch-categories';
import { fetchBadges } from '../../app/actions/fetch-badges';
import { subscribeToChange } from '../../common/services/state-optimizer';
import { showJoinCommunityPopup } from '../../common/actions/join-community';
import {
  isMaInstalled,
  setCommunitiesContext,
} from '../../common/store/communities-context/communities-context-actions';
import { initializeMaNavigation } from '../../common/store/ma-navigation/ma-navigation-actions';
import { parseInstance } from '../../common/store/instance-values/parse-instance';
import { setBasicParams } from '../../common/store/basic-params/basic-params-actions';
import { fetchExperiments } from '../../common/actions/fetch-experiments';
import { isRtlLanguage } from '../../common/services/is-rtl-language';
import { fetchUser } from '../../common/actions/fetch-user';
import listenToSettingsChange from '../../app/services/listen-to-settings-change';
import { navigateWithinForum } from '../../common/actions/navigate-within-forum';
import { fetchTranslations } from '../../common/store/translations/translations-actions';
import { initializeLocation } from '../../common/store/location/location-actions';
import createInteractionsMiddleware from '../../../app/middleware/create-interactions-middleware';
import { initialiseInstanceValues } from '../../common/store/instance-values/instance-values-actions';
import { handleProvisioning } from '../../common/services/handle-provisioning';
import { getFromMemoryCache, saveInMemoryCache } from '../../common/services/memory-cache';
import { forumsUouBiMiddleware } from '../../app/bi-events/init-middleware';
import { handleError } from '../../common/store/debug-state/handle-error';
import {
  LIST_NUMBER_OF_POSTS,
  WIDGET_CATEGORY_ID,
  WIDGET_POSTS_ORDER_BY,
} from '../constants/wix-params';
import { validateLanguage } from '../../common/services/validate-language';
import { SENTRY_VIEWER_SCRIPT_DSN } from '../../common/constants/sentry-viewer-dsn';
import { setComponentData } from '../../common/store/component-data/component-data-actions';
import { requestLoginPromisified } from '../../app/actions/request-login';
import { fetchForumData } from '../../app/actions/fetch-forum-data';
import { setSavedAction } from '../../common/store/saved-action/set-saved-action';
import { invokeSavedAction } from '../../app/services/invoke-saved-action';

const isProduction = process.env.NODE_ENV === 'production';

const createMemoryStorageCacheKey = compId => `recent-posts-widget:${compId}:memory`;

export const createRecentPostsWidgetController = (
  { appParams, compId, config, setProps, wixCodeApi, platformAPIs, type },
  allCtrls,
) => {
  const memoryStorageCacheKey = createMemoryStorageCacheKey(compId);
  const isDebug = wixCodeApi.location.query.debug;
  const isSSR = wixCodeApi.window.rendering.env === 'backend';
  const log = createLogger(isDebug, isProduction);
  log('createRecentPostsWidgetController', { appParams, allCtrls });

  const { fedOpsLoggerFactory } = platformAPIs;
  const sentry = new Raven.Client();
  sentry.config(SENTRY_VIEWER_SCRIPT_DSN, {
    dataCallback: data => {
      data.environment = isSSR ? 'RPW-Worker-SSR' : 'RPW-Worker-CSR';
      return data;
    },
  });

  const fedopsLogger = fedOpsLoggerFactory.getLoggerForWidget({
    appId: appParams.appDefinitionId,
    widgetId: type,
  });
  fedopsLogger.appLoadStarted();
  const fedopsAppLoaded = once(() => fedopsLogger.appLoaded());

  const memoryCachedStore = getFromMemoryCache(platformAPIs, memoryStorageCacheKey);

  const pageReady = () => {
    log('createRecentPostsWidgetController.pageReady -> start');

    const language = validateLanguage(wixCodeApi.site.language);

    const store = createReduxStore({
      appParams,
      memoryCachedStore,
      wixCodeApi,
      compId,
      reducers,
      fedopsLogger,
      platformAPIs,
      language,
      isDebug,
    });
    const actions = initializeActions({ wixCodeApi, compId, store, fedopsLogger, fedopsAppLoaded });
    const actionsPromisified = initializePromisifiedActions({ wixCodeApi, compId, store });

    const promise = memoryCachedStore
      ? Promise.resolve()
      : initializeStoreData({ wixCodeApi, store, config, language });
    return promise
      .then(() => {
        log('createRecentPostsWidgetController.pageReady -> done');
        const state = store.getState();

        setProps({
          actions,
          actionsPromisified,
          state,
          cssBaseUrl: appParams.baseUrls.staticsBaseUrl,
          isRTL: isRtlLanguage(language),
          isSSR,
        });

        if (wixCodeApi.window.viewMode === 'Editor') {
          listenToSettingsChange(store);
        }

        if (isSSR) {
          fedopsAppLoaded();
        }

        subscribeToChange(store, stateDiff => setProps({ state: stateDiff }));
        saveInMemoryCache(platformAPIs, memoryStorageCacheKey, filterStateForCache(state));
        updateLocalCacheOnStateChange({ platformAPIs, store, memoryStorageCacheKey });

        return state;
      })
      .catch(
        handleError({
          store,
          setProps,
          appParams,
          captureToSentry: e => sentry.captureException(e),
        }),
      );
  };

  return Promise.resolve({
    pageReady: () => {
      if (wixCodeApi.window.viewMode === 'Editor') {
        return handleProvisioning(appParams, fedopsLogger, wixCodeApi, setProps, pageReady);
      }
      return pageReady();
    },
    exports: () => ({}),
  }).catch(console.error);
};

function updateLocalCacheOnStateChange({ store, platformAPIs, memoryStorageCacheKey }) {
  const debouncedUpdate = debounce(
    () =>
      // TODO: check in future if this performs, might need to do this selectively
      setTimeout(() => saveInMemoryCache(platformAPIs, memoryStorageCacheKey, store.getState())),
    200,
    { maxWait: 1000 },
  );
  store.subscribe(debouncedUpdate);
}

function createReduxStore({
  reducers,
  appParams,
  wixCodeApi,
  compId,
  fedopsLogger,
  memoryCachedStore,
  platformAPIs,
  language,
  isDebug,
}) {
  const origin = uri(wixCodeApi.location.baseUrl).origin();
  const baseUrls = appParams.baseUrls;

  const baseUrl =
    wixCodeApi.window.rendering.env === 'backend'
      ? `${origin}${baseUrls.apiBaseUrlClient}`
      : baseUrls.apiBaseUrlClient;

  const badgesApiBaseUrl =
    wixCodeApi.window.rendering.env === 'backend'
      ? `${origin}${baseUrls.apiBadgesBaseUrlClient}`
      : baseUrls.apiBadgesBaseUrlClient;

  const request = createRequest({
    baseUrl,
    getInstance: () => wixCodeApi.user.currentUser.instance,
    locale: language,
    petriOvr: wixCodeApi.location.query.petri_ovr,
    siteRevision: wixCodeApi.site.revision,
  });

  const badgesRequest = createRequest({
    baseUrl: badgesApiBaseUrl,
    getInstance: () => wixCodeApi.user.currentUser.instance,
    locale: language,
    petriOvr: wixCodeApi.location.query.petri_ovr,
    siteRevision: wixCodeApi.site.revision,
  });

  return createStore(
    reducers,
    memoryCachedStore ? memoryCachedStore : undefined,
    composeWithReduxDevTools(wixCodeApi)(
      applyMiddleware(
        thunk.withExtraArgument({
          request,
          wixCodeApi,
          compId,
          appParams,
          fedopsLogger,
          badgesRequest,
        }),
        createInteractionsMiddleware(fedopsLogger, {
          [INCREMENT_POST_LIKE_COUNT_SUCCESS]: LIKE_POST,
        }),
        forumsUouBiMiddleware(platformAPIs, appParams.instanceId, wixCodeApi),
      ),
    ),
  );
}

function initializeActions({ wixCodeApi, store, fedopsLogger, fedopsAppLoaded }) {
  return {
    appLoaded: fedopsAppLoaded,
    interactionStarted: interaction => fedopsLogger.interactionStarted(interaction),
    interactionEnded: interaction => fedopsLogger.interactionEnded(interaction),

    requestLogin: mode => {
      const options = {};
      if (mode !== undefined) {
        options.mode = mode;
      }
      wixCodeApi.user.promptLogin(options).catch(noop);
    },

    ...bindActionCreators(
      {
        navigateWithinForum,
        fetchRecentPosts,
        incrementPostLikeCount,
        showJoinCommunityPopup,
        setSavedAction,
        fetchBadges,
      },
      store.dispatch,
    ),
  };
}

function initializeStoreData({ wixCodeApi, store, config: { publicData, style }, language }) {
  const viewMode = wixCodeApi.window.viewMode.toLowerCase();
  const promises = [];

  promises.push(
    initUserDependentStoreData({ store, publicData, style, wixCodeApi }),
    isMaInstalled(wixCodeApi).then(isInstalled => {
      if (isInstalled) store.dispatch(initializeMaNavigation());
    }),
    store.dispatch(initializeLocation()),
    store.dispatch(setCommunitiesContext()),
    store.dispatch(fetchForumData()),
    store.dispatch(fetchTranslations(language)),
    store.dispatch(setBasicParams({ viewMode, language })),
    store.dispatch(setComponentData(get(publicData, 'COMPONENT', {}))),
  );

  wixCodeApi.user.onLogin(() => {
    setTimeout(() => {
      initUserDependentStoreData({ store, publicData, style, wixCodeApi }).then(() =>
        invokeSavedAction(store),
      );
    });
  });

  return Promise.all(promises);
}

function initUserDependentStoreData({ store, publicData, style, wixCodeApi }) {
  const user = wixCodeApi.user.currentUser;
  const { biToken } = parseInstance(wixCodeApi.user.currentUser.instance);
  const isSiteSaved = biToken !== undefined;
  return Promise.all([
    user.loggedIn
      ? store.dispatch(fetchUser({ siteMemberId: wixCodeApi.user.currentUser.id }))
      : Promise.resolve(),
    store.dispatch(fetchRecentPostsAction({ publicData, style })),
    store.dispatch(fetchAllCategories()),
    isSiteSaved ? store.dispatch(fetchBadges()) : Promise.resolve(),
    store.dispatch(fetchExperiments()),
    store.dispatch(initialiseInstanceValues(wixCodeApi.user.currentUser.instance)),
  ]);
}

function fetchRecentPostsAction({ publicData, style }) {
  return fetchRecentPosts({
    categoryId: get(publicData.COMPONENT, WIDGET_CATEGORY_ID),
    orderBy: get(publicData.COMPONENT, WIDGET_POSTS_ORDER_BY, 'createdDate'),
    pageSize: get(style.styleParams.numbers, LIST_NUMBER_OF_POSTS, 3),
  });
}

function composeWithReduxDevTools(wixCodeApi) {
  const shouldEnable =
    wixCodeApi.location.query.debug && wixCodeApi.window.rendering.env !== 'backend';

  return shouldEnable
    ? composeWithDevTools({
        name: 'rpw',
        realtime: shouldEnable,
        port: 8000,
      })
    : f => f;
}

function createLogger(isDebug, isProduction) {
  return (...args) => {
    if (!isProduction || isDebug) {
      console.log(...args);
    }
  };
}

function filterStateForCache(state) {
  return {
    ...state,
    appLoaded: { hasReported: false },
  };
}

function initializePromisifiedActions({ store }) {
  return {
    ...bindActionCreators(
      {
        requestLoginPromisified,
      },
      store.dispatch,
    ),
  };
}
