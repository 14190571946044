import { PAID_PLANS_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import { getApplicationConfig } from '../../../../common/selectors/communities-context-selectors';
import { fetchPaidPlans } from '../../../actions/fetch-paid-plans';

export const manageCategoryMembersPlansModalDataLoader = () => (dispatch, getState) => {
  const state = getState();
  if (getApplicationConfig(state, PAID_PLANS_APP_ID).isInstalled) {
    return dispatch(fetchPaidPlans());
  }
};
